body, html, #root {
    background-color: #1A1A1A;
    overflow: hidden;
    height: 100%;
    margin: 0;
    padding: 0;
}

/*
===========================
== Animations
===========================
*/

/* Fade */
.fadeOut {
    opacity: 0
}

.fadeIn {
    opacity: 1
}

.bg-dark-cus {
    background-color: #222;
}

.bg-dark-cus .card-header {
    background-color: rgb(22, 22, 22);
}

.text-white-cus {
    color: white;
}

.modal-white-text, .modal-white-text button {
    color: white;
}

.modal-white-text button {
    text-shadow: none;
}

.modal-white-text button:hover {
    color: rgb(245, 245, 245)
}

.text-a-cus {
    color: #DDD;
}

.text-a-cus.active {
    color: #222;
    background-color: #DDD;
}

.text-a-cus:hover {
    color: #999;
}

.text-a-cus.active:hover {
    color: #222;
}

.navbar {
    border-bottom: 3px solid white;
}

.mb-n-5 {
    margin: 0;
}

.pl-6 {
    padding-left: 4rem;
}

.column-4 {
    -webkit-column-count: 4; 
    -moz-column-count: 4;
    column-count: 4;
    -webkit-column-gap: 1rem;
    -moz-column-gap: 1rem;
    column-gap: 1rem;
    -webkit-column-width: 33%;
    -moz-column-width: 33%;
    column-width: 33%;
}

.column-3 {
    -webkit-column-count: 3; 
    -moz-column-count: 3;
    column-count: 3;
    -webkit-column-gap: 1rem;
    -moz-column-gap: 1rem;
    column-gap: 1rem;
    -webkit-column-width: 33%;
    -moz-column-width: 33%;
    column-width: 33%;
}

.column-2 {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
    -webkit-column-gap: 1rem;
    -moz-column-gap: 1rem;
    column-gap: 1rem;
    -webkit-column-width: 33%;
    -moz-column-width: 33%;
    column-width: 33%;
}

.column-1 {
    -webkit-column-count: 1;
    -moz-column-count: 1;
    column-count: 1;
    -webkit-column-gap: 1rem;
    -moz-column-gap: 1rem;
    column-gap: 1rem;
    -webkit-column-width: 33%;
    -moz-column-width: 33%;
    column-width: 33%;
}

.column-3 img {
    width: 100%;
    margin-bottom: 1rem;
}

.column-2 img {
    width: 100%;
    margin-bottom: 1rem;
}

.column-1 img {
    width: 100%;
    margin-bottom: 1rem;
}

.scrollDiv {
    overflow-y: auto;
}

.galleryDiv {
    height: 90%;
    z-index: 5;
}

.w-90 {
    width: 90%;
}

.h-80 {
    height: 81%;
}

.w-md-100 {
    width: auto !important;
}

.btn.btn-noLine:focus {
    box-shadow: none;
}
 
/* Links inside the dropdown */
.dropdown-menu a {
    background-color: none;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}
  
/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-menu {display: block;}

#wrapper {
    height: 100%;
}

#home, #gallery, #projects, #about {
    height: 100%;
}

.bg {
    height: 90%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.cont {
    position: relative;
    width: 100%;
    cursor: pointer;
    opacity: 0;
}

.cont iframe {
    width: 100%;
    height: auto;
}

.cont-img {
    position: relative;
    width: 100%;
    cursor: pointer;
}

/* [1] The container */
.img-hover-zoom {
    height: 100%; /* Set diminsions */
    width: 100%; /* Set diminsions */
    overflow: hidden; /* [1.2] Hide the overflowing of child elements */
}
  
/* [2] Transition property for smooth transformation of images */
.img-hover-zoom img {
    transition: transform .5s ease;
}
  
/* [3] Finally, transforming the image when container gets hovered */
.img-hover-zoom:hover img {
    transform: scale(1.3);
}

/* Animation Videos */
.videoWrapper {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    height: 0;
}
.videoWrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
/* Animation Videos */

.image {
    opacity: 1;
    display: block;
    width: 100%;
    height: auto;
    transition: .5s ease;
    backface-visibility: hidden;
}
  
.middle {
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
}
  
.cont:hover .image {
    opacity: 0.3;
}
  
.cont:hover .middle {
    opacity: 1;
}
  
.text {
    color: white;
    font-size: 16px;
    padding: 10px 10px;
}

.border-sm-right-2 {
    border-right: 2px solid #DDD;
}

.border-lg-right-2 {
    border-right: 2px solid #DDD;
}

.modal-dialog-cus {
    height: auto;
    max-width: 75%;
}

.h-sm-100 {
    height: 100%;
}

.m-0-cus {
    margin: 0;
    margin-top: -3rem;
}

.img-holder, .site-holder {
    text-align: center;
    height: 80vh;
    width: 60%;
}

#img-holder:-webkit-full-screen { width: 100%; height: 100%; float: none; }
#img-holder:-moz-full-screen { width: 100%; height: 100%; float: none; }
#img-holder:-ms-fullscreen { width: 100%; height: 100%; float: none; }
#img-holder:fullscreen { width: 100%; height: 100%; float: none; }

#img-holder:-webkit-full-screen img { max-height: 100%; margin: 0 auto; }
#img-holder:-moz-full-screen img { max-height: 100%; margin: 0 auto; }
#img-holder:-ms-fullscreen img { max-height: 100%; margin: 0 auto; }
#img-holder:fullscreen img { max-height: 100%; margin: 0 auto; }

.helper {
    display: inline-block;
    height: 100%;
    vertical-align: middle;
}

.panel_gallery {
    position: relative;
    max-width: 100%;
    height: auto;
    max-height: 100vh;
    vertical-align: middle;
}

.btn-full-right-cus {
    height: 100px;
    position: absolute;
    right: 1rem;
    z-index: 10;
}

.btn-full-left-cus {
    height: 100px;
    position: absolute;
    left: 1rem;
    z-index: 10;
}

/*
===========================
== Checkbox Styling
===========================
*/

.control {
    font-family: arial;
    display: block;
    position: relative;
    padding-left: 40px;
    margin-bottom: 5px;
    padding-top: 3px;
    cursor: pointer;
    font-size: 25px;
}
.control input {
    position: absolute;
    z-index: -1;
    opacity: 0;
}
.control_indicator {
    position: absolute;
    top: 4px;
    left: 0;
    height: 30px;
    width: 30px;
    background: #cac2c2;
    border: 1px solid #adadad;
    border-radius: 20px;
}
.control:hover input ~ .control_indicator,
.control input:focus ~ .control_indicator {
    background: #aa8a8a;
}

.control input:checked ~ .control_indicator {
    background: #b64040;
}
.control:hover input:not([disabled]):checked ~ .control_indicator,
.control input:checked:focus ~ .control_indicator {
    background: #b86464;
}
.control input:disabled ~ .control_indicator {
    background: #949494;
    opacity: 0.6;
    pointer-events: none;
}
.control_indicator:after {
    box-sizing: unset;
    content: '';
    position: absolute;
    display: none;
}
.control input:checked ~ .control_indicator:after {
    display: block;
}
.control-checkbox .control_indicator:after {
    left: 10px;
    top: 4px;
    width: 6px;
    height: 14px;
    border: solid #ffffff;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
}
.control-checkbox input:disabled ~ .control_indicator:after {
    border-color: #2e2222;
}
.control-checkbox .control_indicator::before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 4.5rem;
    height: 4.5rem;
    margin-left: -1.3rem;
    margin-top: -1.3rem;
    background: #2aa1c0;
    border-radius: 3rem;
    opacity: 0.6;
    z-index: 99999;
    transform: scale(0);
}
.remove_item {
    width: 30px;
    height: 30px;
    display: inline-block;
    background-color: red;
    border-radius: 100%;
}
.remove_item p {
    margin-top: -9px;
    font-size: larger;
}
.item_text {
    width: 90%;
    display: inline-block;
}

/*
===========================
== Logged In Stylings
===========================
*/

.form-gallery {
    color: white;
    position: relative;
    z-index: 1;
    margin-top: -1rem;
    padding-top: 1rem;
}

/*
===========================
== Animations
===========================
*/

@keyframes s-ripple {
    0% {
        transform: scale(0);
    }
    20% {
        transform: scale(1);
    }
    100% {
        opacity: 0;
        transform: scale(1);
    }
}
@keyframes s-ripple-dup {
   0% {
       transform: scale(0);
    }
   30% {
        transform: scale(1);
    }
    60% {
        transform: scale(1);
    }
    100% {
        opacity: 0;
        transform: scale(1);
    }
}
.control-checkbox input + .control_indicator::before {
    animation: s-ripple 250ms ease-out;
}
.control-checkbox input:checked + .control_indicator::before {
    animation-name: s-ripple-dup;
}

/*
===========================
== Media Queries
===========================
*/

/* Large Devices */ 
@media only screen and (max-width: 991px) {
    .column-4 {
        -webkit-column-count: 3;
        -moz-column-count: 3;
        column-count: 3;
        -webkit-column-gap: 1rem;
        -moz-column-gap: 1rem;
        column-gap: 1rem;
        -webkit-column-width: 33%;
        -moz-column-width: 33%;
        column-width: 33%;
    }

    .column-3 {
        -webkit-column-count: 2; 
        -moz-column-count: 2;
        column-count: 2;
        -webkit-column-gap: 1rem;
        -moz-column-gap: 1rem;
        column-gap: 1rem;
        -webkit-column-width: 33%;
        -moz-column-width: 33%;
        column-width: 33%;
    }

    .border-lg-right-2 {
        border-right: none;
    }

    .modal-dialog-cus {
        height: auto;
        max-width: 100%;
    }
}

/* Medium Devices */ 
@media only screen and (max-width: 767px) {
    .column-4 {
        -webkit-column-count: 2;
        -moz-column-count: 2;
        column-count: 2;
        -webkit-column-gap: 1rem;
        -moz-column-gap: 1rem;
        column-gap: 1rem;
        -webkit-column-width: 33%;
        -moz-column-width: 33%;
        column-width: 33%;
    }
    
    .column-3 {
        -webkit-column-count: 2;
        -moz-column-count: 2;
        column-count: 2;
        -webkit-column-gap: 1rem;
        -moz-column-gap: 1rem;
        column-gap: 1rem;
        -webkit-column-width: 33%;
        -moz-column-width: 33%;
        column-width: 33%;
    }

    .column-2 {
        -webkit-column-count: 1;
        -moz-column-count: 1;
        column-count: 1;
        -webkit-column-gap: 1rem;
        -moz-column-gap: 1rem;
        column-gap: 1rem;
        -webkit-column-width: 33%;
        -moz-column-width: 33%;
        column-width: 33%;
    }

    .w-md-100 {
        width: 100% !important;
    }
}

/* Extra Small Devices */ 
@media only screen and (max-width: 575px) {
    /* A fix for small screens not scrolling properly */
    .scrollDiv.galleryDiv {
        height: calc(100% - 150px);
    }

    .border-sm-right-2 {
        border-right: none;
    }

    .h-sm-100 {
        height: 0;
    }
}